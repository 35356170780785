module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":768,"quality":100,"withWebp":true,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.datoybi.com/","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Steady-Dev","short_name":"Steady-Dev","start_url":"./index.html","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","icon":"src/images/icons/favicon.png","icons":[{"src":"./src/images/icons/icon-57x57.png","sizes":"57x57","type":"image/png"},{"src":"./src/images/icons/icon-60x60.png","sizes":"60x60","type":"image/png"},{"src":"./src/images/icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"./src/images/icons/icon-76x76.png","sizes":"76x76","type":"image/png"},{"src":"./src/images/icons/icon-114x114.png","sizes":"114x114","type":"image/png"},{"src":"./src/images/icons/icon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"./src/images/icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"./src/images/icons/icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"./src/images/icons/favicon.png","sizes":"64x64","type":"image/icon","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"315cb5c8b8973602e34a3e248cd67bb0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
